@import '../../scss/theme-bootstrap';

.elc-product-brief {
  .elc-product {
    &-images-wrapper {
      background: $color-lightest-gray;
      min-height: 350px;
      img,
      picture,
      video {
        aspect-ratio: 97 / 99;
      }
      .sd-product-grid & {
        aspect-ratio: 1;
        min-height: auto;
      }
      @include breakpoint($landscape-up) {
        min-width: 406px;
        min-height: 414px;
      }
    }
    &-display-name-wrapper {
      display: inline-block;
      margin: 10px 0 5px;
      .elc-product-display-name-link {
        @include NeueHaasGrotesk-black;
        font-size: 26px;
        line-height: 22px;
        margin-bottom: 12px;
      }
    }
    &-subheader {
      display: none;
    }
    &-short-description-wrapper {
      @include NeueHaasGrotesk-regular;
      color: $color-black;
      font-size: 15px;
      line-height: 20px;
      margin: 0 0 5px;
      text-transform: initial;
      @include breakpoint($landscape-up) {
        font-size: 17px;
      }
    }
    &-badge {
      @include NeueHaasGrotesk-regular;
      margin-#{$ldirection}: 20px;
      background: $color-black;
      border-radius: 6px;
      color: $color-white;
      font-size: 16px;
      height: 27px;
      line-height: 18x;
      min-width: 50px;
      padding: 5px 8px;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 20px;
      width: auto;
    }
    &-prices-wrapper,
    &-prices-clickable-wrapper {
      display: block;
      .elc-product-prices-wrapper {
        width: 100%;
      }
      .elc-product-tax-included-label-wrapper {
        display: none;
      }
      .elc-product-price-row-wrapper {
        margin: 0;
      }
      .elc-product-price-per-unit-row-wrapper {
        display: none;
      }
      .elc-price-formatted-wrapper {
        display: inline-block;
        .elc-price-formatted {
          @include NeueHaasGrotesk-bold;
          color: inherit;
          font-size: 17px;
          line-height: 15px;
          margin: 0;
        }
        &:last-child {
          color: $color-sbx-red;
          &:first-child {
            color: $color-black;
          }
        }
      }
    }
    &-full-cta-wrapper {
      box-shadow: none;
      position: relative;
      @include breakpoint($landscape-up) {
        bottom: 0;
        position: absolute;
        width: 100%;
      }
      .elc-product-notify-me-button,
      .elc-add-to-bag-button {
        @include button-rounded-base;
        @include button--rounded-dark;
        font-size: 13px;
        height: 40px;
        overflow: hidden;
        width: 160px;
        @include breakpoint($landscape-up) {
          font-size: 16px;
          height: 44px;
          width: 187px;
        }
        &-disabled:hover,
        &-disabled {
          background: $color-silver;
          border-color: $color-silver;
          color: $color-sbx-dgrey;
        }
      }
    }
    &-selected-shade-wrapper {
      .elc-single-shade-wrapper {
        justify-content: flex-start;
        padding: 0;
        position: relative;
        margin-top: 5px;
        div {
          border-radius: inherit;
          height: 17px;
          margin-#{$rdirection}: 5px;
          width: 27px;
          position: absolute;
          top: 3px;
          @include breakpoint($landscape-up) {
            height: 12px;
            width: 20px;
          }
        }
        p {
          @include NeueHaasGrotesk-medium;
          @include swap_direction(margin, 0 0 0 35px);
          color: $color-black;
          font-size: 17px;
          line-height: 20px;
          overflow: hidden;
          text-transform: uppercase;
          @include breakpoint($landscape-up) {
            @include swap_direction(margin, 0 0 0 28px);
          }
        }
        .elc-shade-icon {
          &[color=''] {
            ~ .elc-subtitle--3 {
              margin: 0;
            }
          }
          &[color^='#'] {
            ~ .elc-subtitle--3 {
              min-height: 20px;
            }
          }
        }
      }
    }
    &-shade-picker {
      min-height: 40px;
      &-slider {
        margin-top: -3px;
        padding: 0;
      }
      .elc-single-shade-wrapper {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        p {
          display: none;
        }
        > div {
          border-radius: inherit;
          height: 30px;
          width: 100%;
        }
      }
      .elc-shade-image {
        border-bottom: 3px solid $color-white;
        border-radius: 0;
        height: 26px;
        margin: 3px 0 0;
        overflow: hidden;
        padding: 0;
        width: 99%;
        @include breakpoint($landscape-up) {
          height: 30px;
        }
        svg {
          transform: scale(1.5);
        }
        &.elc-shade-image-selected {
          border-bottom: 1px solid $color-white;
          box-shadow: 0 2px $color-black;
        }
        &:hover {
          @include breakpoint($landscape-up) {
            #{$ldirection}: 2px;
            border: 1px solid $color-white;
            height: 30px;
            outline: 2px solid $color-black;
            top: -1px;
            width: 93%;
          }
        }
      }
      .elc-slider-arrow-wrapper {
        top: 8px;
        @include breakpoint($landscape-up) {
          top: 10px;
        }
        &.slick-prev {
          #{$ldirection}: 2px;
          z-index: 1;
        }
        &.slick-next {
          #{$rdirection}: 3px;
        }
        .elc-directional-icon {
          background-color: $color-white;
        }
      }
      .elc-grid-column {
        padding: 0;
      }
    }
    &-name-wrapper {
      margin: 0;
    }
    &-rating-wrapper {
      justify-content: flex-start;
      .elc-read-reviews {
        display: none;
      }
      .elc-rating {
        margin: 0;
      }
      .elc-stars-simplified,
      .elc-stars-simplified-stars {
        @include icon('stars', before);
        height: 30px;
        overflow: hidden;
        position: relative;
        width: 96px;
        &:before {
          color: $color-nobel-gray;
          font-size: 95px;
          height: 30px;
          position: absolute;
          top: -35px;
          width: 130px;
        }
      }
      .elc-stars-simplified-stars:before {
        color: $color-black;
      }
      .elc-rating-reviews,
      .elc-average-rating-text {
        display: none;
      }
    }
    &-sorting-dropdown {
      border-radius: 9px;
      border: 1px solid $color-black;
      min-width: 200px;
    }
  }
  .elc-media-asset {
    .elc-product-images-wrapper {
      min-height: initial;
      min-width: initial;
    }
  }
  a.elc-clickable-wrapper {
    text-decoration: none;
  }
  .elc-size-picker {
    &-box,
    &-box-selected {
      @include NeueHaasGrotesk-regular;
      @include swap_direction(margin, 12px 8px 5px 0);
      background: $color-white;
      border-radius: 9px;
      border: 1px solid $color-sbx-dgrey;
      color: $color-sbx-dgrey;
      font-size: 14px;
      line-height: 20px;
      min-width: 120px;
      width: auto;
    }
    &-box-selected {
      border-color: $color-black;
      color: $color-black;
    }
    &-wrapper {
      > div {
        display: none;
        &:last-child {
          display: block;
          margin: 0 0 5px;
        }
      }
      .elc-selected-size {
        @include NeueHaasGrotesk-regular;
        color: $color-black;
        font-size: 14px;
        line-height: 20px;
        margin-top: 5px;
        width: auto;
      }
      .slick-slide {
        width: auto !important;
      }
      .slick-track {
        justify-content: flex-start;
      }
      .slick-slider {
        margin-#{$ldirection}: -10px;
      }
    }
  }
  .elc-rating-component {
    margin: 0;
  }
}

.elc-responsive-modal-mask {
  .elc-responsive-modal {
    top: 37%;
    .elc-product-notify-me-pop-up-close-button {
      background: $color-white;
    }
    .elc-product-notify-me-content {
      .elc-product-notify-me-info {
        &-button {
          background: $color-white;
        }
      }
    }
  }
}

.sd-grid-skeleton-items {
  display: grid;
  grid-auto-flow: dense;
  gap: 0px;

  .elc-img {
    height: auto;
    width: 100%;
  }

  .sd-skeleton-shade-picker {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .elc-shade-image-wrapper {
      box-sizing: border-box;
    }

    button.elc-shade-image {
      border: none;

      &:hover {
        border: none;
        width: 99%;
        outline: none;
        box-shadow: none;
      }
    }
  }
}

// Moved from React inline style.
.elc-size-picker-container {
  .slick-track {
    display: flex;
    width: inherit !important;
  }
}